import React from "react"
import CustomAccordion from "../Accordion"
import "./CenterArticle.scss"

export const CenterArticle = ({ data: { title, description, accordion } }) => {
  return (
    <div className="center wrapper center-article-wrapper">
      <article className="center">
        <h2 className="h2-text mg-y-md">{title}</h2>
        <p className="text">{description}</p>
        <CustomAccordion data={accordion} />
      </article>
    </div>
  )
}
