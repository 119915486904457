import { Link, navigate } from "gatsby"
import React from "react"
import "./style.scss"

const CVParsingCustomCard = () => {
  return (
    <div className="custom-card center">
      <div className="wrapper">
        <h2 className="h2-text">Do you want to know more about the product?</h2>
        <p className="h4-text">
          Try out a demo and find out how to make the most of DrParser to optimize your recruitment
        </p>
        <div className="horizontal-buttons">
          <button
            onClick={() => {
              navigate("/products/cv-parsing/demo")
            }}
            className="fade-btn"
          >
            Demo DrParser CV parsing <span className="custom-icon">&#x27A1;</span>
          </button>
          <button
            onClick={() => {
              navigate("/products/cv-parsing/packages")
            }}
            className="fade-btn"
          >
            Check out our packages <span className="custom-icon">&#x27A1;</span>
          </button>
        </div>
        <br />
        <p className="h4-text">
          Or, Read more about API usage at our{" "}
          <Link href="#">documentation</Link>
        </p>
      </div>
    </div>
  )
}

export const CVParsingCustomCardNoButton = () => {
  return (
    <div className="custom-card center">
      <div className="wrapper">
        <h2 className="h2-text">Accuracy Testing</h2>
        <p className="h4-text">
          We’ve examined DrParser’s accuracy to compare it with other tools. The following charts showcase how Neurond’s CR Parser stands out from the crowd in extracting diverse data fields, from Name, Phone number, and Email address to Education, Experience, Certificates, and more.
        </p>
      </div>
    </div>
  )
}

export default CVParsingCustomCard
