import React from "react"
import { useTranslation } from "react-i18next"
import Banner from "../../components/Banner/Banner"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import { CenterArticle } from "../../components/CenterArticle/CenterArticle"
import CVParsingCustomCard, {
  CVParsingCustomCardNoButton,
} from "../../components/CVParsing/CVParsingCustomCard"

const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/HomePage/home_page_slider.webp`

const data1 = {
  title: "Why use CV parsing?",
  description: "A resume or CV parser comes as an AI-based software to convert data from resumes into a structured format intelligently. It quickly identifies and stores critical data points such as working experience, education, skills, and qualifications. This software allows you to organize and analyze resume data much more accurately, eliminate manual work, and automate your recruitment process. As a result, it performs better searchability and analysis of your data.",
}
const data2 = {
  title: "How Recruiters Benefit from CV Parsers",
  description: "Resume parsers bring considerable benefits to entrepreneurs, particularly companies that receive a great number of applications. The tool assists you in automatically extracting crucial information from resumes, saving you time and energy.",
  accordion: [
    {
      title: "Fast and automated applicant data processing",
      description:
        "Resume parsers free you from manually reviewing individual CVs, increasing accuracy and efficiency. You can spend your time building a good relationship with candidates or personalizing candidate outreach.",
    },
    {
      title: "Organizes applicants better",
      description:
        "CV parsers help you organize applicants better based on certain skill sets or accomplishments within the talent pool. You can use this data to identify candidates who possess the skills and experience you are hunting for.",
    },
    {
      title: "Eliminate bias and increase diversity",
      description:
        "We can’t en sure that every recruiter will never put personal emotions into candidate considerations. But AI tools can. They always operate tasks logically, so there is no room for personal bias. Chances are, your organization won’t lose potential applications due to personal prejudices.",
    },
  ],
}

const data3 = {
  title: "Why DrParser?",
  description: "DrParser concentrates on speed and accuracy to bring the highest parsing performance. ",
  accordion: [
    {
      title: "Deliver the highest quality result",
      description:
        "DrParser always delivers up to 95% correct information after each extraction, especially in complicated fields like Experience, Activities, or Certificates. We also continuously look into new ways to optimize our analysis and extraction model. ",
    },
    {
      title: "Dramatically reduce time to process and shortlist candidates ",
      description:
        "It takes you less than 2 seconds to parse a CV using DrParser, while you have to wait up to 5 seconds with the help of other similar tools. You may find it not a matter when having a few resumes to parse. In case there are thousands of CVs to handle, you’re wasting a lot of your precious time on this process.",
    },
    {
      title: "Bulk import",
      description:
        "This feature lends you a helping hand in efficiently importing tons of resumes in a go, rather than going over them one by one. It’s incredibly advantageous when you receive a resume feed from a third-party recruitment agency or other sources.",
    },
    {
      title: "Configure extracted data fields",
      description:
        "It’s possible for you to disable specific fields when extracting data. For example, without noticing age, gender, and marital status information, recruiters can evaluate candidates impartially without bias.",
    },
  ],
}

const bannerData = {
  title: ["DrParser – CV/Resume Parsing Software"],
}
const CVParsingPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t("cv_parsing")} />
      <div className="margin-bot">
        <Banner item={bannerData} backgroundImage={bannerImg} />
        <div className="ellipse" />
      </div>
      <section className="one-article">
        <CenterArticle data={data1} />
      </section>
      <section>
        <CVParsingCustomCard />
      </section>
      <section className="one-article">
        <CenterArticle data={data2} />
      </section>
      <section>
        <CVParsingCustomCardNoButton />
      </section>
      <section className="one-article margin-bot">
        <CenterArticle data={data3} />
      </section>
    </Layout>
  )
}

export default CVParsingPage
